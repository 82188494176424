.price__banner{
    background-image: url("../../assets/pricingbanner.png");
    height: 300px;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
   align-items: center;

    
}

.price__banner h2{
    color: white;
    font-size: 25px;
}


.price__banner input {
    -webkit-appearance: none;
    position: relative;
    width: 60px;
    height: 30px;
    border-radius: 25px;
    background-color: #ccc;
    outline: none;
    cursor: pointer;
   
}

.price__banner input::after{
    content: '';
    position: absolute;
    top: 50%;
    left: 30%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    height: 1.25rem;
    width: 1.25rem;
    background-color: rgb(255, 255, 255);
    transition: left .3s;
}

.price__banner input:checked{
    background-color: #29ad8e;
}

.price__banner input:checked::after{
    left: 70%;
}

.price__banner p{
        color: white;
        font-weight: 600;
}

.price__card {
    background-color: rgb(255, 255, 255);
    padding: 25px;
    border-radius: 20px;
    box-shadow: 0px 0px 2px 2px #c1c1c1;
}

.pricecardrow{
    margin-top: -100px;
   position: relative;
   background-image: url("../../assets/patternleft.png");
}



.pricecardrow::before{
    position: absolute;
    background-image: url("../../assets/bluepattern1.png");
   
}

.price__span{
    font-weight: 700;
    font-size: 30px;

}

.blueline{
    border-bottom: 5px solid #3489CE;
    width: 60px;
    border-radius: 5px;
}

.pricing__feature{
    border-bottom: 1px solid black;
   
}

.pricing__feature p{
font-size: 25px;
font-weight: 500;
}

.table-hover td {
border: none;
}

.table-hover th {
    border: none;
    }

    .tablebackodd{
        background-color: #DBE7FF;
        border-radius: 25px;
       
    }

    .icontick{
        background-color: #06CB1A;
        border-radius: 50%;
        padding: 2px;
    }

    th{
        width: 50%;
    }

    .crossicon{
        background-color: #E93C3E;
        border-radius: 50%;
        padding: 1px;
    }

    .pricingbtn{
        background-color: #E9F0FE;
        color: #3489CE;
        font-weight: 500;
    }

    .pricingbtn:hover{
       color: #E9F0FE;
        background-color: #3489CE;
        font-weight: 500;
    }

    .table2 th{
        font-size: 16px;
        font-weight: 400;
    }

    button.btn.btn__cardprice a {
        color: #fff;
        text-decoration: none;
    }

    
    .btn__cardprice:hover{
        color: white;
background-color: #3489ce;
    }

    @media only screen and (max-width:991px){
        .pricecardrow{
           margin-bottom: 30px;
        }
    }

    @media only screen and (max-width:767px){
        .pricecardrow{
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .resp__card1{
           
            padding-left: 35%;
            margin-bottom: 20px;
            
        }

        .hiddendiv{
            display: none;
        }
    }
    ul.pricing-list {
        padding: 0;
    }
    ul.pricing-list li {
        display: block;
    }
    ul.pricing-list li svg.iconify.iconify--material-symbols {
        font-size: 25px;
        margin-right: 5px;
    }
    ul.pricing-list li {
        padding: 5px 0px;
    }